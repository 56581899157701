<template>
  <div>
    <UserTable v-if="isUser" suffix="resTabPreU"
        :query="resTabDefU"
        showShow showDelete showProtest showResolve showPagination
        :noHitText="$t('res.rpno')" />
    <CustomerTable v-if="!isUser" suffix="resTabPreC"
        :query="resTabDefC"
        showShow showDelete showPagination
        :noHitText="$t('res.rpno')" />
  </div>
</template>

<script>
import UserTable from '@/components/reservations/UserTable';
import CustomerTable from '@/components/reservations/CustomerTable';

export default {
  data() {
    return {
      resTabDefU: { q: 'startAt:le:{N},state:nin:CANCELED;REJECTED', s: '-startAt', p: '10' },
      resTabDefC: { q: 'startAt:le:{N},userUuid4:n:{U},state:nin:CANCELED;REJECTED', s: '-startAt', p: '10' },
    }
  },
  computed: {
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
  },
  components: {
    UserTable,
    CustomerTable,
  },
}
</script>
